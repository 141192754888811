import { fabric } from "fabric";
import { Observable } from "rxjs";

export class SimpleCanvas {
  private canvas: any;
  private devices: Map<number, fabric.Group> = new Map<number, fabric.Group>();

  public init(
    canvasId: string,
    backgroundImage: string
  ): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      this.canvas = new fabric.Canvas(canvasId);

      let img = new Image();

      img.onload = () => {
        this.canvas.setHeight(img.height);
        this.canvas.setWidth(img.width);

        this.canvas.setBackgroundImage(
          backgroundImage,
          this.canvas.renderAll.bind(this.canvas),
          {
            backgroundImageStretch: false
          }
        );
        resolve({ width: img.width, height: img.height });
      };

      img.src = backgroundImage;
    });
  }

  // TODO: Is there a way to get this by an id rather than index??
  public showWarning(deviceId: number) {
    this.devices
      .get(deviceId)
      .item(1)
      .set({ radius: 5 });
    this.canvas.renderAll();
  }

  public clearWarning(deviceId: number) {
    this.devices
      .get(deviceId)
      .item(1)
      .set({ radius: 0 });
    this.canvas.renderAll();
  }

  public setText(deviceId: number, text: string) {
    this.devices
      .get(deviceId)
      .item(2)
      .set('text', text);
    this.canvas.renderAll();
  }

  public addDevice(deviceId: number, img: string, x: number, y: number) {
    let stream = Observable.create(observer => {
      var deviceImg = new Image();
      deviceImg.onload = img => {

        var device = new fabric.Image(deviceImg, {
          left: 0,
          top: 0
        });

        var circle = new fabric.Circle({
          radius: 0,
          fill: "red",
          left: 0,
          top: 0
        });

        var text = new fabric.Text(
          '',
          {
            left: 0,
            top: device.height + 10,
            fontSize: 14
          });

        var group = new fabric.Group([device, circle, text], {
          left: x,
          top: y
        });

        group.on("moved", result =>
          observer.next({
            type: "moved",
            data: { x: result.target.left, y: result.target.top }
          })
        );

        group.on("mousedown", () => {
          observer.next({ type: "mousedown" });
        });

        //this.canvas.add(device);
        //this.canvas.add(circle);
        this.canvas.add(group);
        this.devices.set(deviceId, group);
      };
      deviceImg.src = img;
    });

    return stream;
  }
}
