<div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
    <h1 mat-dialog-title>Simulator</h1>

    <form [formGroup]="simulatorForm" (ngSubmit)="onSubmit()">
        <mat-form-field>
            <mat-label>
                Labs
            </mat-label>
            <mat-select formControlName="lab" #lab (selectionChange)="onLabSelected(lab.value)">
                <mat-option value="{{ lab }}" *ngFor="let lab of labs || []">{{ lab }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>
                Steps
            </mat-label>
            <mat-select formControlName="step" #step>
                <mat-option value="{{ step }}" *ngFor="let step of steps || []">{{ step }}</mat-option>
            </mat-select>
        </mat-form-field>

    </form>

    <mat-dialog-actions align="end">
        <button mat-button (click)="onCancel()">Cancel</button>
        <button mat-button [disabled]="!simulatorForm.valid" type="button" (click)="onSubmit()">
            Submit
        </button>
    </mat-dialog-actions>
</div>