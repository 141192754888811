import { environment as env } from "../../../environments/environment";

import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { CreateDeviceComponent } from "../create-device/create-device.component";

import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

import { SimpleCanvas } from "../../../lib/simple-canvas";
import { CreateAlarmComponent } from "src/app/alarm/create-alarm/create-alarm.component";

import { Device, Location, Alarm, SensorReading } from "../../model";
import { GlobalData } from "src/app/app.config";
import { ListAlarmsComponent } from "src/app/alarm/list-alarms/list-alarms.component";
import { ViewDeviceComponent } from "../view-device/view-device.component";

@Component({
  selector: "app-location",
  templateUrl: "./location.component.html",
  styleUrls: ["./location.component.css"]
})
export class LocationComponent implements OnInit {

  id: string;
  canvas: SimpleCanvas;
  location: Location;
  devices: Device[];
  clickedDevice: Device;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private G: GlobalData,
    private dialog: MatDialog
  ) { }

  initCanvas() {
    this.canvas = new SimpleCanvas();
    this.canvas.init("draw-area", this.location.image_data).then(dimensions => {
      let containers = document.getElementsByClassName(
        "container"
      ) as HTMLCollectionOf<HTMLDivElement>;
      for (let i = 0; i < containers.length; i++) {
        containers[i].style.width = `${dimensions.width}px`;
      }
    });


    this.http
      .get<Device[]>(
        `${env.apiEndpoint}/locations/${this.id}/devices`,
        { headers: this.G.getHeaders() }
      )
      .subscribe(
        response => {
          response.map(device => this.onDeviceCreated(device));
          setInterval(() => this.refreshSensorData(), 3000);
        },
        (error: HttpErrorResponse) => {
          console.log("Error is " + error);
        }
      );
  }

  viewHistorian() {
    this.router.navigate([
      "historian",
      "location",
      this.location.id,
      "device",
      this.clickedDevice.id
    ]);
  }

  viewDetails() {
    //href="/location/create"
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.clickedDevice.point_data;

    const dialogRef = this.dialog.open(ViewDeviceComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result == null) return;
      //window.location.reload();
    });
  }

  listAlarms() {
    //href="/location/create"
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      locationId: this.id,
      deviceId: this.clickedDevice.id,
      alarms: this.clickedDevice.alarms
    };

    const dialogRef = this.dialog.open(ListAlarmsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result == null) return;
      // handle response
    });
  }

  createAlarm() {
    //href="/location/create"
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      locationId: this.id,
      device: this.clickedDevice
    };

    const dialogRef = this.dialog.open(CreateAlarmComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result == null) return;
      //window.location.reload();
    });
  }

  createDevice() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { locationId: this.id };

    const dialogRef = this.dialog.open(CreateDeviceComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: Device) => {
      if (result == null) return;
      this.onDeviceCreated(result);
    });
  }


  onDeviceCreated(device: Device) {
    if (device == null) return;

    var imageURL = "/assets/devices/" + device.image_path;

    this.canvas
      .addDevice(device.id, imageURL, device.x, device.y)
      .subscribe(result => {
        switch (result.type) {
          case "moved": {
            this.moveDevice(device, result.data.x, result.data.y);
          }
          case "mousedown": {
            this.showDeviceMenu(device);
            break;
          }
          default: {
            break;
          }
        }
      });
  }

  showDeviceMenu(device: Device) {
    this.clickedDevice = device;
  }

  moveDevice(device: Device, newX: number, newY: number) {
    //let device = this.devices.find(item => item.id == deviceId);
    device.x = newX;
    device.y = newY;


    this.http
      .put(
        `${env.apiEndpoint}/locations/${this.id}/devices/${device.id}/canvas-parms`,
        device,
        { headers: this.G.getHeaders() }
      )
      .subscribe(
        response => {
        },
        (error: HttpErrorResponse) => {
          console.log("Error is " + error);
        }
      );
  }

  refreshSensorData() {

    this.http
      .get<Device[]>(
        `${env.apiEndpoint}/locations/${this.id}/devices`,
        { headers: this.G.getHeaders() }
      )
      .subscribe(
        response => {
          this.devices = response;
          for (let device of this.devices) {
            let s = "";
            for (let key of Object.keys(device.point_data)) {
              let showPoint =
                device.display_points.find(item => item.name === key) !=
                undefined;

              if (showPoint) {
                let myschema = this.G.getSchema();
                let deviceSchema = myschema[device.schema];
                let units = '';
                let value = device.point_data[key];
                if (deviceSchema[key]['data_type'] == 'number') {
                  units = deviceSchema[key]['units']
                  value = Number(value).toFixed(3);
                }
                s += `${key}: ${value} ${units}\n`;
              }
            }
            this.canvas.setText(device.id, s);

            // TODO: We probably don't want this to automatically clear
            // even if the issue is resolved. Probably want use interation.
            this.canvas.clearWarning(device.id);
            for (let alarm of device.alarms) {
              if (alarm.is_triggered) {
                this.canvas.showWarning(device.id);
              }
            }
          }
        },
        (error: HttpErrorResponse) => {
          console.log("Error is " + error);
        }
      );
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");

    this.http
      .get<Location>(`${env.apiEndpoint}/locations/${this.id}`,
        { headers: this.G.getHeaders() }
      )
      .subscribe(
        response => {
          this.location = response;
          this.initCanvas();
        },
        (error: HttpErrorResponse) => {
          console.log("Error is " + error);
        }
      );
  }
}
